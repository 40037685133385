@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
}

.container-cd {
  padding: 20px 0px 0px;
  margin: 0px auto;
  width: 100%;
}
h2 {
  margin-top: 0px !important;
}
.container-cd.dark {
  background: #000;
}

.container-cd.light {
  background: #f8f8f8;
}

.content {
  margin: 0px auto;
  padding: 20px 20px 50px;
  max-width: 1350px;
  text-align: center;
}
p {
  margin: unset !important;
}
.nav-content {
  margin: 0px auto;
  max-width: 1350px;
  text-align: center;
  padding: 0px 15px;
}
.nav-c-m-w {
  width: 85% !important;
  justify-content: space-between !important;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-elem-img {
  width: 100px;
  mix-blend-mode: darken;
}
nav .nav-elem {
  color: #000;
  text-decoration: none;
  font-size: 22px;
  letter-spacing: 1px;
  padding: 3px 25px;
  transition: all ease 0.8s;
}
nav .nav-elem:hover {
  background: rgb(115 96 89 / 59%);
  border-radius: 30px;
}

.big-title {
  text-align: center;
  font-size: 50px;
  padding-bottom: 12px;
  letter-spacing: 1px;
  font-family: "DM Sans", sans-serif;
}

.big-title-light {
  color: #fff;
}

.elements-container {
  display: flex;
  flex-wrap: wrap;
}

.elements-container div {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.elements-container .principale {
  width: 300px;
  flex: 1 0 200px;
  border: 2px solid #f8f8f8;
  border-radius: 50% 20% / 10% 40%;
  padding: 20px;
  margin: 20px;
}

.elements-container .principale .icon-containe img {
  width: 100px;
  mix-blend-mode: darken;
}
.elements-container .partner .logo-partner img {
  width: 300px;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: darken;
}

.elements-container .principale .icon-containe {
  background: #f8f8f8;
  width: fit-content;
  padding: 15px;
  border-radius: 50%;
}

.elements-container .partner {
  flex: 1 0 200px;
  padding: 10px;
  margin: 10px;
}

.principle-title {
  font-size: 22px;
  font-weight: lighter;
  color: #f8f8f8;
  letter-spacing: 1.5px;
}

.text-light {
  font-size: 20px;
  letter-spacing: 1.5px;
  color: #f8f8f8;
}

.intro-text {
  font-size: 22px;
  letter-spacing: 1px;
  color: #000;
  margin: 20px 0px !important;
}

.partner-name {
  font-size: 19px;
  letter-spacing: 1.5px;
  font-weight: lighter;
  color: #000;
  margin-top: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0px auto;
}

.form-container input,
.form-container textarea {
  width: 80%;
  margin: 10px auto;
  padding: 6px 0 0 20px;
  font-size: 18px;
  border-radius: 30px;
  border: none;
}

.form-container input {
  height: 50px;
}

.form-container textarea {
  height: 200px;
}

.form-container input:focus,
.form-container textarea:focus {
  outline: unset;
}

.btn-cd {
  width: fit-content;
  background: #736059;
  margin: 10px auto;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 24px;
  padding: 10px 36px;
  border: 2px solid #736059;
  border-radius: 32px;
  transform: translate(0);
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.btn-cd::before {
  content: "";
  position: absolute;
  background: #fff;
  width: 8px;
  top: 0;
  bottom: 0;
  left: -32px;
  transform: rotate(-16deg);
  filter: blur(6px);
}

.btn-cd:hover::before {
  left: calc(100% + 32px);
  transition: ease 1s;
}

.info-contact {
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px 20px;
  justify-content: space-between;
}

.info-contact .logo-info img {
  mix-blend-mode: darken;
  width: 180px;
}

.info-contact .infos-addr-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.info-contact .infos-addr-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-contact .infos-addr-content .icon {
  width: 35px;
  background: black;
  border-radius: 32px;
  padding: 5px;
  margin-right: 5px;
  transition: all ease 0.8s;
}

.info-contact .infos-addr-content .icon:hover {
  margin-bottom: 15px;
  transition: all ease 0.5s;
}

@media only screen and (max-width: 963px) {
  .info-contact {
    gap: 25px;
    justify-content: center;
  }

  .form-container {
    width: 95%;
  }
  .home-container {
    flex-direction: column;
  }
  .text-home-content {
    width: auto !important;
  }
  .home-container img {
    display: none;
  }
}
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #231f20;
  padding: 20px 30px;
  text-align: left;
}
.text-home-content {
  width: 55%;
}
.home-container img {
  width: 45%;
}
.header-title {
  font-size: 56px;
  letter-spacing: 1.2px;
  margin: 20px 0px;
}
.sub-title {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 0px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.service {
  width: 80%;
  margin: auto;
}
.service img {
  width: 300px;
  margin: 0px auto;
  mix-blend-mode: darken;
}
.service .service-desc {
  text-align: left;
  font-size: 20px;
}

:root {
  --swiper-navigation-size: 30px !important;
  --swiper-navigation-color: #000;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

.line {
  width: 100%;
  height: 2px;
  margin-bottom: 30px;
  animation: line-animation 2s ease-out;
}
.line.light {
  background-color: #fff;
}
.line.dark {
  background-color: #000;
}
@keyframes line-animation {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.wrapper-1 {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper-2 {
  padding: 30px;
  text-align: center;
}
.thankyou-title {
  font-family: "Kaushan Script", cursive;
  font-size: 4em;
  letter-spacing: 3px;
  color: #736059;
  margin: 0;
  margin-bottom: 20px;
}
a.btn-cd,
.btn-a {
  text-decoration: none !important;
  color: #fff !important;
}
.wrapper-2 p {
  margin: 0;
  font-size: 1.3em;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
}

@media (min-width: 360px) {
  .thankyou-title {
    font-size: 4.5em;
  }
}

@media (min-width: 600px) {
  .thankyou-content {
    margin: 0 auto;
  }
  .wrapper-1 {
    height: fit-content;
    max-width: 620px;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 4px 8px 40px 8px rgba(115, 96, 89, 0.2);
  }
}
